<template>
  <v-navigation-drawer
    class="users-sidebar grey lighten-5"
    absolute
    permanent
    floating
    stateless
    width="240px"
  >
    <template v-slot:prepend>
      <div class="users-sidebar__headline headline blue-grey--text text--darken-3">Users</div>
    </template>
    <v-list>
      <template v-for="usersSelectElement in usersSelectElements">
        <v-divider :key="`${usersSelectElement.pageName}-divider`" />

        <v-list-item
          :key="usersSelectElement.pageName"
          class="users-sidebar-select pa-0"
          color="transparent"
          active-class="users-sidebar-select--active"
          :to="{ name: usersSelectElement.pageName }"
        >
          <v-list-item-content class="users-sidebar-select__title subtitle-1 font-weight-bold">
            {{ usersSelectElement.title }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { USERS_PAGE } from '@/constants/users-page';

const USERS_SIDEBAR_ELEMENTS = {
  ADMINS_AND_DOCTORS: {
    title: 'Admins&Doctors',
    pageName: USERS_PAGE.ADMINS_AND_DOCTORS
  },
  CUSTOMERS: {
    title: 'Customers',
    pageName: USERS_PAGE.CUSTOMERS
  }
};

const SIDEBAR_ELEMENTS = Object.values(USERS_SIDEBAR_ELEMENTS);

export default {
  name: 'UsersSidebar',
  data() {
    return {
      usersSelectElements: SIDEBAR_ELEMENTS
    };
  }
};
</script>

<style lang="scss" scoped>
.users-sidebar {
  padding: 25px 25px 25px 15px;

  &__headline {
    padding-left: 5px;
    margin-bottom: 37px;
  }
}

.users-sidebar-select {
  cursor: pointer;

  &--active {
    .users-sidebar-select__title {
      color: #37474f;
    }
  }

  &__title {
    padding: 18px 5px;
    color: #90a4ae;
  }
}
</style>
