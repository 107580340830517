<template>
  <div class="users-page fill-height">
    <users-sidebar />
    <div class="users-page__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import UsersSidebar from '../components/users-page/UsersSidebar.vue';

import { USERS_PAGE, USERS_PAGES_NAMES } from '@/constants/users-page';

const INITIAL_USERS_PAGE = USERS_PAGE.ADMINS_AND_DOCTORS;

export default {
  name: 'UsersPage',
  components: { UsersSidebar },
  mounted() {
    const route = this.$route.matched.find(matchedRoute =>
      USERS_PAGES_NAMES.includes(matchedRoute.name)
    );

    if (route || this.$route.name === INITIAL_USERS_PAGE) {
      return;
    }

    this.$router.replace({ name: INITIAL_USERS_PAGE });
  }
};
</script>

<style lang="scss" scoped>
.users-page {
  position: relative;

  &__content {
    height: 100%;
    padding-left: 240px;
  }
}
</style>
